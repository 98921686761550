import React from 'react'
import { Layout } from 'antd';

import TreeHeader from './TreeHeader';
import TreeContent from './TreeContent';

const { Content } = Layout;
const Tree = () => {
    return (
        <Layout>
            <TreeHeader />
            <TreeContent />
        </Layout>
    )
}

export default Tree;