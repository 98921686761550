import React, { useState } from 'react'
import { PlusCircleFilled, CloseOutlined } from '@ant-design/icons';



const TabCloseIcon = ({ changeDetected }) => {
    const [hover, setHover] = useState(false);
    return (
        <span
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {
                changeDetected && !hover ?
                    <PlusCircleFilled style={{ color: "white", background: "white", borderRadius: "100%" }} />
                    :
                    <CloseOutlined style={{ fontSize: "13px" }} />
            }
        </span>
    )
}

export default TabCloseIcon;