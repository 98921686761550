export const sendMessage = (msgObj) => {
  window.parent.postMessage({ ui: true, ...msgObj }, "*");
};

export const sendScripterMessage = (msgObj) => {
  window.parent.postMessage({ scripter: true, ...msgObj }, "*");
};

export const sendMessageInteg = (msgObj) => {
  const el = window.document.querySelector("#integ");
  el && el.contentWindow.postMessage(msgObj, "*");
};

export const recieveMessage = (key, action) => {
    window.addEventListener('message', (evt) => {
        let data = evt.data;
        if (data && data.engine && data.type && data.type === key) {
            action(data);
        }
    }, false);
}

export const promiseMessage = (msgObj) => {
    return new Promise((resolve, reject) => {
        window.addEventListener('message', (evt) => {
            let data = evt.data;
            if (data && data.engine && data.type === msgObj.type) {
                resolve(data);
            }
        }, false);
        sendScripterMessage(msgObj);
    })
    
}
