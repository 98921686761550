import { notification } from 'antd';

export const uuid = () => {
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
   });
}

export const ellipsis = (text, maxLength) => {
   maxLength = maxLength || 30;
   if (text && text.length > maxLength)
      text = text.substr(0, maxLength) + "...";
   return text;
}

export const toastSuccess = (successMsg, durationToDisplay = null) => {
   notification.success({
      message: 'Success',
      description: `${successMsg}`,
      placement: 'bottomRight',
      duration: durationToDisplay ?? 5
   });
}

export const toastInfo = (successMsg, durationToDisplay = null) => {
   notification.info({
      message: 'Info',
      description: `${successMsg}`,
      placement: 'bottomRight',
      duration: durationToDisplay ?? 5
   });
}

export const toastError = (errMsg) => {
   notification.error({
      message: 'Error',
      description: `${errMsg}`,
      placement: 'bottomRight',
      duration: 5
   });
}

export const arrayCompare = (array1, array2) => {
   return JSON.stringify(array1) === JSON.stringify(array2);
}

export const ensureArray = (data) => Array.isArray(data) ? data : [data];
export const arrayValuesToLowerCase = (data) => data.map((item) => item?.toLowerCase());