import React, { useState } from 'react'
import { Layout } from 'antd';

import SearchHeader from './SearchHeader';
import SearchContent from './SearchContent';


const Search = () => {
    const [searchData, setSearchData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    return (
        <Layout>
            <SearchHeader setSearchData={setSearchData} setSearchQuery={setSearchQuery} />
            <SearchContent searchData={searchData} searchQuery={searchQuery} />
        </Layout>
    )
}

export default Search;