import React, { useContext, createContext, useState } from "react";


const PreviewContext = createContext();

function PreviewProvider({ children }) {
    const [activeError, setActiveError] = useState(null);


    return (
        <PreviewContext.Provider
            value={
                {
                    activeError,
                    setActiveError
                }
            }>
            {children}
        </PreviewContext.Provider>
    );
}

function useScripterPreview() {
    const context = useContext(PreviewContext);
    if (context === undefined) {
        throw new Error(`useScripter must be used within a TreeContext`);
    }
    return context;
}

export { PreviewProvider, useScripterPreview };
