import React from "react";
import "styled-components/macro";
import { Tabs } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import ValidatorsErrorsContainer from "./ValidatorErrors/ValidatorsErrorsContainer";
import { colors } from "Shared/colors";
const { TabPane } = Tabs;
import VisualFilter from "./VisualFilter";

const Container = styled.main`
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0;
    padding-top: 8px;
    padding-left: 2px;
    background: linear-gradient(to right, #360095, #4e0e7e);
    color: rgba(255, 255, 255, 0.75);
    height: 48px;
    box-shadow: inset 0px 6px 4px -5px rgb(0 0 0);
  }

  /* set background color for tab */
  & .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: ${colors.SCRIPTER_PREVIEW_BLACK};
  }
  /* set color bottom border of tab to match background color */
  & .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: ${colors.SCRIPTER_PREVIEW_BLACK};
  }

  /* set tab text color */
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }

  /* color border of tab to match background color */
  & .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid transparent;
  }

  /* remove border bottom from top of tabpanel */
  & .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }

  & .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 6px 16px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32px;
  gap: 1px;

  /* defines font size for icon */
  font-size: 16px;
`;

const TabText = styled.span`
  font-size: 9px;
  line-height: 9px;
  font-weight: 600;
`;

const StyledTabPane = styled(TabPane)`
  background-color: ${colors.SCRIPTER_PREVIEW_BLACK};
  height: calc(100vh - 48px * 2 - 40px);
`;

const PreviewContent = ({ validatorsErrors }) => {
  return (
    <Container>
      <Tabs type="card">
        <StyledTabPane
          tab={
            <TabContainer>
              <CloseCircleOutlined style={{ margin: 0 }} />
              <TabText>Errors</TabText>
            </TabContainer>
          }
          key="errors"
        >
          <ValidatorsErrorsContainer validatorsErrors={validatorsErrors} />
        </StyledTabPane>
      </Tabs>
      <VisualFilter />
    </Container>
  );
};

export default PreviewContent;
