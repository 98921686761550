import React from "react";
import styled from "styled-components";
import { Typography, Switch } from 'antd';
const { Text } = Typography;

const StyledText = styled(Text)`
  color: white;
  font-size: 0.7rem;
  font-family: NotoSans-Regular;
`;
const ErrorText = ({ text }) => {
  return (
      <StyledText
          style={{ width: 180 }}
          ellipsis={{ tooltip: text }}
      >
          {text}
      </StyledText>
  );
};

export default ErrorText;
