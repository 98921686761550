import React from 'react';
import { Tooltip, Icon } from 'antd';
import styled from 'styled-components';
import {InfoCircleOutlined} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive'


const StyledIcon = styled(InfoCircleOutlined)`
    color: #1794FF;
    font-size: 0.75rem;
	margin-left: 0.2rem;
`

const StyledContainer = styled.div`
    display: inline-flex;
    line-height: 1.5rem;
`;

const StyledLabelRequired = styled.label`
    &:before {
        display: inline-block;
        margin-right: 0.2rem;
        color: #f5222d;
        font-size: 0.75rem;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
`;

const Label = ({required, htmlFor, value, style}) => {
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)'
    });

    if(required){
        return <StyledLabelRequired style={{fontSize: isMobile ? '0.6rem' : '0.7rem', fontWeight: 700}} htmlFor={htmlFor}>{value}</StyledLabelRequired>
    }
    else {
        return <label style={{fontSize: isMobile ? '0.6rem' : '0.7rem', fontWeight: 700}} htmlFor={htmlFor}>{value}</label>
    }
}

export const TooltipLabel = ({htmlFor, value, tooltip, fontSizeRem, maringBottomStyle, required}) => {
    return (
      <>
        <StyledContainer maringBottomStyle={maringBottomStyle}>
            <Label 
                style={{fontSize: fontSizeRem + 'rem'}}
                htmlFor={htmlFor}
                value={value}
                required={required}
            />
            {
                !tooltip.hidden &&
                <Tooltip placement="right" title={<div>{tooltip.text}</div>}>
                    <StyledIcon />
                </Tooltip>
            }
        </StyledContainer>
      </>
    )
  }