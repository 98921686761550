import { Modal } from 'antd';
import styled from "styled-components";
import { backgrounds } from 'Shared/colors';

const StyledModal = styled(Modal)`

${props => props.publishModal == 'true' &&
        ` 
        width: 300px !important;

        .ant-modal-body{
            padding: 10px !important;
        }
        `}

.ant-modal-header{
    background: ${backgrounds.PURPLE_GRADIENT};
    border-bottom: solid black 1px;
    box-shadow: 0px 5px 11px 4px rgb(0 0 0 / 15%);
    z-index: 123123123132131;
    padding: 10px 24px;
    border-radius: 6px 6px 0 0;

    .ant-modal-title{
        color: white !important;
        font-size: .9rem;
    }
}

.ant-modal-content{
    border-radius: 7px;
}

.ant-checkbox-group{
    margin-left: 35px;
    margin-top: 10px;
}

.ant-modal-close{
    top: -7px;

    .ant-modal-close-x{
        color: white;
        opacity: 0.5;
        height: 20px;
    }
}

.ant-modal-footer{
    border: none;
    
    ${props => props.publishModal == 'true' &&
        ` 
        text-align: center;
        `}

    .ant-btn-primary{
        background: ${backgrounds.PURPLE_GRADIENT};
        border: none;

        &:hover{
            opacity: 0.7;
        }

    }
}
`;

export { StyledModal };