import { sendMessage, recieveMessage } from "Shared/messages";

export const getCurrentUrl = () => {
    return new Promise((resolve, reject) => {
        sendMessage({ type: "url" });
        recieveMessage("url", (response) => {
          if (response && response.url) {
            return resolve(response.url);
          }
          return reject(['Url not found']); 
        });
    })
}

export const getSnippetUrl = () => {
  return new Promise((resolve, reject) => {
      sendMessage({ type: "snippetUrl" });
      recieveMessage("snippetUrl", (response) => {
        if (response && response.snippetUrl) {
          return resolve(response.snippetUrl);
        }
        return reject(['snippetUrl not found']); 
      });
  })
}