import styled from "styled-components";
import { Avatar } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const InitialsSign = styled(Avatar)`
    color: #360095;
    font-size: .8rem;
    font-weight: bold;
    background-color: white;
    opacity: 50%;
    width: 24px;
    height: 24px;

    .ant-avatar-string{
        position: absolute;
        top: -4px;
    }
`;

const ChangeSize = styled.img`
    height: 16px;
    width: 16px;
    cursor: pointer;
    opacity: 75%;
`;

const StyledDivider = styled.span`
  border-left: solid black 1px;
  border-right: solid white 1px;
  opacity: 30%;
  height: 25px;
`;

const ArrowIcon = styled(DownOutlined)`
    background-color: black;
    height: 35px;
    position: absolute;
    width: 20px;
    border-radius: 0px 5px 5px 0px;

    svg{
        background-color: black;
        opacity: 1;
        color: white;
        margin-top: 13px;
        height: 10px;
    }

    transition: all .5s ease-in-out;

    ${props => props.ismenuvisible == 'true' ?
        `opacity: 0;
        margin-left: 50px;
        ` :
        `opacity: 1;
        margin-right: 50px;
        `
    }; 
    `;

const U1Heading = styled.h1`
    display: flex;
    flex-direction: row;
    gap: 2px;
    color: white;
    opacity: 75%;
    margin-bottom: 0;
    font-size: 16px;
    font-family: Rubik;
    font-weight: 500;
`;


export { InitialsSign, ChangeSize, StyledDivider, ArrowIcon, U1Heading };

