const USER1ST_STORAGE_PREFIX = "User1st.";

export class Storage {
    static SetItem(name, value) {
        window.localStorage.setItem(`${USER1ST_STORAGE_PREFIX}${name}`, value);
    }
    static GetItem(name) {
        return window.localStorage.getItem(`${USER1ST_STORAGE_PREFIX}${name}`);
    }
    static RemoveItem(name) {
        window.localStorage.removeItem(`${USER1ST_STORAGE_PREFIX}${name}`);
    }
}