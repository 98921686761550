import React from "react";
import styled from "styled-components";




const StyledSpin = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;


 span {
  position: absolute;
  display: block;
  bottom: 10px;
  width: 9px;
  height: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-animation: uSpinner 1.5s  infinite ease-in-out;
          animation: uSpinner 1.5s  infinite ease-in-out;
}

 span:nth-child(2) {
  left: 11px;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

span:nth-child(3) {
  left: 22px;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

 span:nth-child(4) {
  left: 33px;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

 span:nth-child(5) {
  left: 44px;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

@keyframes uSpinner {
  0% {
    height: 5px;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: white;
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    background: #4e0e7e;
  }
  50% {
    height: 5px;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: white;

  }
  100% {
    height: 5px;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: white;
  }
}
@-webkit-keyframes uSpinner {
  0% {
    height: 5px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background: #360095;
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    background: #4e0e7e;
  }
  50% {
    height: 5px;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: rgba(255, 255, 255, 0.25);

  }
  100% {
    height: 5px;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background: rgba(255, 255, 255, 0.25);
  }
}
`;


const PreviewSpiner = () => {
    return (
        <StyledSpin id="uSpinner">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </StyledSpin>
    )


};

export default PreviewSpiner;
