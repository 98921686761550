import React from 'react'
import styled from "styled-components";
import { colors } from 'Shared/colors';


const StyledTitle = styled.span`
    color: ${props => props.errorDetected ? colors.RED : props.isActive ? "white" : colors.LIGHT_GREY};
`;


const TabTitle = ({ title, errorDetected, isActive }) => {

    return (
        <StyledTitle errorDetected={errorDetected} isActive={isActive} >{title}</StyledTitle>
    )
}

export default TabTitle;