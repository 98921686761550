


const updateErrorDetected = (list, key, errorDetected) => {
    return list.map((node) => {
        if (node.key === key) {
            return { ...node, errorDetected };
        } else if (node.children) {
            return { ...node, children: updateErrorDetected(node.children, key, errorDetected) };
        }
        return node;
    });
}

const updateScriptContent = (list, newNode) => {
    return list.map((node) => {
        if (node.key === newNode.key) {
            return newNode;
        } else if (node.children) {
            return { ...node, children: updateScriptContent(node.children, newNode) };
        }
        return node;
    });
}


const renameUpdateTreeData = (list, key, newItem) => {
    if (key) {
        return list.map((node) => {
            if (node.key === key) {
                const newNode = { ...node };
                newNode.children = newNode.children.map(c => c.key === newItem.key ? { ...c, title: newItem.title } : c);
                return newNode;
            } else if (node.children) {
                return { ...node, children: renameUpdateTreeData(node.children, key, newItem) };
            }
            return node;
        });
    }
    return list.map(c => c.key === newItem.key ? { ...c, title: newItem.title } : c);
}


const tempReplaceTreeData = (list, key, newItem) => {
    if (key) {
        return list.map((node) => {
            if (node.key === key) {
                const newNode = { ...node };
                newNode.children = newNode.children.map(c => c.key === newItem.key ? newItem : c)
                return newNode;
            } else if (node.children) {
                return { ...node, children: tempReplaceTreeData(node.children, key, newItem) };
            }
            return node;
        });
    }
    return list.map(c => c.key === newItem.key ? newItem : c);
}

const fixReplaceTreeData = (list, key, newItem) => {
    if (key) {
        return tempReplaceTreeData(list, key, newItem);
    }
    return [...list, newItem];
}

const moveUpdateTreeData = (list, key, newItem) => {
    if (key) {
        return list.map((node) => {
            if (node.key === key) {
                const newNode = { ...node };
                newItem.path = [...newNode.path, newNode.key];
                newNode.children.push(newItem);
                return newNode;
            } else if (node.children) {
                return { ...node, children: moveUpdateTreeData(node.children, key, newItem) };
            }
            return node;
        });
    }
    newItem.path = [];
    return [...list, newItem];
}

const tempUpdateTreeData = (list, key, newItem) => {
    if (key) {
        return list.map((node) => {
            if (node.key === key) {
                const newNode = { ...node };
                newNode.children.push(newItem);
                return newNode;
            } else if (node.children) {
                return { ...node, children: tempUpdateTreeData(node.children, key, newItem) };
            }
            return node;
        });
    }
    return [...list, newItem];
}

const removeTempUpdateTreeData = (list, key, newItem,) => {
    if (key) {
        return list.map((node) => {
            if (node.key === key) {
                const newNode = { ...node };
                newNode.children = newNode.children.filter(n => n.key !== newItem.key)
                return newNode;
            } else if (node.children) {
                return { ...node, children: removeTempUpdateTreeData(node.children, key, newItem) };
            }
            return node;
        });
    }
    return list.filter(n => n.key !== newItem.key);
}

const getAllChildrenKeys = (node) => {
    let keys = [node.key];
    if (node.children && node.children.length) {
        node.children.forEach(c => keys = keys.concat(getAllChildrenKeys(c)));
    }
    return keys;
}
export { moveUpdateTreeData, updateScriptContent, fixReplaceTreeData, renameUpdateTreeData, tempReplaceTreeData, tempUpdateTreeData, removeTempUpdateTreeData, getAllChildrenKeys, updateErrorDetected };