import React from 'react';

const ErrorAvatar = ({ color, count }) => {
    return (
        <div style={{
            width: "24px",
            height: "24px",
            lineHeight: "24px",
            backgroundColor: color,
            textAlign: "center",
            fontSize: ".7rem",
            fontFamily: "NotoSans-Bold, sans",
            color: "black",
            borderRadius: "2px"
        }} >{count ?? 1}</div>
    )
}

export default ErrorAvatar;